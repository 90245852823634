.user-page-box {
  padding: 16px;
  background: #fff;
  display: flex;
  height: 100%;

  .left-box {
    flex: 1;
  }

  .user-info-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px;

    .info-box {
      padding: 10px;
      background: #F8F8F8;
      display: flex;

      .user-card {
        flex: 1;
        position: relative;
        .member-icon-box {
          position: absolute;
          left: 7px;
          bottom: -20px;
          .svg-icon {
            width: 20px;
            height: 20px;
          }
        }
      }

      .account-box {
        width: 40%;
      }
      .account-box-new {
        width: 20%;
      }
    }
    .member-card-info-box {
      padding: 10px;
      display: flex;
      .user-card {
        flex: 1;
        position: relative;
        .member-icon-box {
          position: absolute;
          left: 7px;
          bottom: -20px;
          .svg-icon {
            width: 20px;
            height: 20px;
          }
        }
        .member-level-box {
          font-size: 14px;
          .member-level {
            padding: 2px 4px;
            color: #fff;
            background-color: red;
            font-size: 12px;
          }
        }
      }

      .account-box {
        width: 40%;
      }
      .account-box-new {
        width: 20%;
      }
    }
    .member-level-line-box {
      width: 100%;
      height: 70px;
      padding: 20px 36px 10px 20px;
      background: #F8F8F8;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .member-level-line {
        display: flex;
        width: 100%;
        height: 8px;
        .member-level-line-item {
          position: relative;
          flex: 1;
          background-color: #7F7F7F;
          .member-level-circle-l {
            position: absolute;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50%;
            .member-level-txt {
              position: absolute;
              left: -20px;
              bottom: -27px;
              width: 52px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              color: var(--font-normal);
              font-size: 12px;
              border-radius: 20px;
              &.active {
                background-color: #F59A23;
                color: #fff;
              }
            }
            .member-level-money-txt {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: 14px;
              width: 132px;
              color: var(--font-normal);
              font-size: 10px;
              text-align: center;
            }
          }
          .member-level-circle {
            position: absolute;
            right: 0;
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50%;
            .member-level-txt {
              position: absolute;
              left: -20px;
              bottom: -27px;
              width: 52px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              color: var(--font-normal);
              font-size: 12px;
              border-radius: 20px;
              &.active {
                background-color: #F59A23;
                color: #fff;
              }
            }
          }
        }
        .member-level-line-item:first-child {
          border-radius: 10px 0 0 10px;
        }
        .member-level-line-item:last-child {
          border-radius: 0 10px 10px 0;
        }
      }
    }

    .order-statistic-box {
      height: 84px;

      .statistic-box-card {
        height: 100%;
      }
    }


  }

  .info-list-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px;

    .info-list-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: var(--font-normal);
      padding: 0 16px;
    }

    .info-list-card-main {
      .null-tips-box {
        padding: 40px 0;
      }

      .list-card-box {
        padding: 16px;
        display: flex;
        align-content: center;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;

        &:last-child {
          border-bottom: 0 none;
        }

        .img-box {
          width: 36px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          margin-right: 8px;

          img {
            max-height: 100%;
            max-width: 100%;
          }

        }

        .main-box {
          flex: 1;
          width: 0;
          line-height: 1;
          font-size: 12px;
          margin-right: 24px;

          .text-title {
            color: var(--font-title);
          }

          .text-tips {
            margin-top: 8px;
            color: var(--font-tips);
          }
        }

        .btn-box {
          .btn {
            width: 64px;
            height: 24px;
            border-radius: 2px;
            border: 1px solid #dcdcdc;
            text-align: center;
            line-height: 22px;
            font-size: 12px;
            color: var(--font-title);
            background: #fff;

            &:hover {
              color: #fff;
              background: var(--color);
            }
          }
        }

        .tips-box {
          font-size: 12px;
          color: var(--font-tips);
        }
      }
    }
  }

  .info-data-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px;

    .info-data-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: var(--font-normal);
      padding: 0 16px;
    }

    .info-data-card-body {
      padding: 16px;
      font-size: 12px;
      color: var(--font-normal);

      .row-card {
        margin-bottom: 10px;
      }

      .iconfont {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        font-size: 12px;
      }
    }
  }

  .right-box {
    width: 290px;
    margin-left: 16px;
  }
}

.account-details-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%;

  .account-details-page-main {
    padding: 16px;
  }

  .milan-coin-details-page-main {
    position: relative;
    .milan-balance {
      position: absolute;
      left: 16px;
      top: -9px;
    }
  }

  .account-details-page-main-head {
    margin-bottom: 8px;

    .input-wrap {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px;
    }

    .select-box {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px;
    }

    .order-statistics-export-btn {
      width: 68px;
      height: 36px;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      border-radius: 6px;
      float: right;
    }
  }

  .account-details-page-main-body {
    .statistics-box {
      height: 96px;
      background: #f5f5f5;
      border: 1px solid var(--border-deep);
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 16px;

      .statistics-item {
        flex: 1;
        text-align: center;

        .number {
          font-size: 20px;
          font-weight: bold;
        }

        .title {
          font-size: 12px;
          color: var(--font-tips);
        }
      }
    }
    .order-statistic-box {
      background-color: #fff;
      border: none;
    }
  }

  .account-details-page-main-foot {
    text-align: center;
    margin-top: 16px;
  }
}

.pick-up-list-page {
  .account-details-page-main {
    .account-details-page-main-head {
      .input-wrap {
        .ant-input-affix-wrapper {
          margin-right: 30px;
        }
      }
    }
  }
  .account-details-page-main-body {
    .cont-table {
      .tr-txt {
        cursor: pointer;
        &.active {
          background-color: pink;
        }
      }
    }
  }
}


.title-line-box {
  padding-left: 12px;
  position: relative;
  font-size: 14px;
  line-height: 28px;
  color: var(--font-title);
  border-bottom: 1px solid var(--border-deep);

  &:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background: var(--font-title);
    position: absolute;
    left: 0;
    top: 8px;
  }
}

.account-page-box {
  margin-top: 14px;
  background: #fff;

  .account-page-body {
    padding: 16px;

    .title-line-box {
      margin-bottom: 16px;
    }

    .form-item-box {
      width: 400px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}


.notices-details-page-box {
  margin-top: 14px;
  background: #fff;

  .notices-details-page-body {
    padding: 16px;
  }

  .attachment-list {
    a {
      display: flex;
    }

    .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .svg-icon {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      flex: 1;
    }
  }
}

.staff-page-box {
  margin-top: 14px;
  background: #fff;

  .staff-page-body {
    padding: 16px;

    .add-btn-box {
      padding-bottom: 16px;

      .link-btn {
        border: 0 none;
        background: transparent;
        color: var(--color);
        font-size: 14px;
        margin-right: 16px;
        display: inline-block;
        vertical-align: middle;

        &[disabled] {
          color: var(--disabled);
        }

        .iconfont {
          margin-right: 4px;
          font-size: 14px;
        }
      }

      .tips {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        color: var(--font-tips);
      }

    }
  }
}

.collect-list-box {
  margin-top: 14px;
  background: #fff;
  min-height: 100%;

  .collect-list-body {
    padding: 16px 0;


    .filter-sheet-wrap {
      padding: 0;
    }

    .sort-sheet-box {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-deep);

      .ope-wrap {
        .btn {
          margin-left: 12px;
          display: inline-block;
          vertical-align: middle;
          width: auto;
          padding: 0 8px;
          font-size: 12px;
          color: var(--font-title);
          background: #f5f5f5;
          border: 1px solid var(--border-deep);
        }

        .link-btn {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          color: var(--font-normal);
          margin-left: 12px;
          cursor: pointer;

          &:hover {
            color: var(--color);
          }

          .iconfont {
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
          }

          .icon-check-s {
            color: var(--color);
          }
        }
      }
    }
  }
}

.help-detail-page-box {
  display: flex;
  width: 100%;

  .notices-details-page-box {
    flex: 1;
  }
}

.view-card-box {
  display: flex;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid var(--border-deep);
  padding: 10px 0;

  .title-box {
    width: 90px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--font-normal);

    .txt {
      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 8px;
        background: var(--border-deep);
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }

  .main-box {
    padding-left: 1em;
    font-size: 14px;
    color: var(--font-title);
    flex: 1;

    &.hint {
      color: var(--font-high);
    }

    .img-box {
      display: inline-block;
      vertical-align: top;
      width: 100px;
      height: 100px;
      border: 1px solid var(--border-deep);
      margin-right: 8px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 98px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

}

.invoice-list-page {
  margin: 16px 0;

  .invoice-list-page-main {
    padding: 16px;
    background-color: #fff;

    .tabs-box {
      .tab {
        display: inline-block;
        vertical-align: middle;
        margin: 0 14px;
        font-size: 14px;
        color: var(--font-normal);
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &:hover {
          color: var(--color);
        }

        &.active {
          color: var(--color);
          border-color: var(--color);
        }
      }
    }
  }

  .invoice-list-table-wrap {
    padding: 16px;

    .cont-table {
      min-height: 320px;
      margin-bottom: 24px;
    }

    .ant-empty {
      margin-top: 32px;
    }
  }
}

.invoice-detail-des {
  padding: 8px 12px;
  background: #FAFAFA;

  .head {
    font-size: 16px;
    font-weight: 700;

    .label {
      color: var(--font-normal);
    }

    .value {
      color: var(--font-title);
    }
  }

  .row {
    margin-top: 6px;

    .label {
      font-size: 14px;
      color: var(--font-normal);
    }

    .value {
      display: inline-block;
      min-width: 130px;
      margin-right: 24px;
      color: var(--font-title);
    }
  }
}

.invoice-detail-attachment {
  padding: 24px;
}

// 优惠券
.coupon-list-page {
  position: relative;
  margin: 16px 0;

  .coupon-center-btn {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 40px;
    font-size: 14px;
    padding-right: 24px;
    color: var(--font-title);
    font-weight: 600;
    cursor: pointer;
  }

  .coupon-list-tabs {
    margin-top: 8px;
    padding: 14px;
    background-color: #fff;

    .coupon-list-tabpane {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .coupon-list-card:nth-child(3n) {
        margin-right: 0;
      }

      .ant-empty {
        display: block;
        margin: 16px auto;
      }
    }
  }

  .coupon-list-card {

    width: 310px;
    margin-right: 16px;
    margin-bottom: 16px;

    .info-box {
      position: relative;
      height: 124px;
      padding: 16px;
      color: #fff;
      background: radial-gradient(circle at left center, transparent 4px, #FF3838 0) left top / 4px 12px repeat-y,
      linear-gradient(to left, #FF6969, #FF3838) 3px / 303px no-repeat,
      radial-gradient(circle at right center, transparent 4px, #FF6969 0) right top / 4px 12px repeat-y;

      .price {
        font-size: 36px;
        line-height: 1;

        &:first-letter {
          font-size: 24px;
        }

        .target {
          font-size: 16px;
          margin-left: 8px;
        }
      }

      .name {
        font-size: 18px;
        line-height: 1;
        margin: 8px 0;
      }

      .time {
        font-size: 14px;
        opacity: .85;
      }

      .tag {
        position: absolute;
        right: 16px;
        top: 0;
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
        border-radius: 0 0 6px 6px;
        color: #FF3838;
        background-color: rgba(255, 205, 205, 1);
      }

      .status-icon {
        position: absolute;
        right: 8px;
        bottom: 8px;
      }
    }

    .intro-box {
      margin-top: -1px;
      border: 1px solid var(--border-deep);
      border-radius: 0 4px 4px 0;
    }

    .explain-box {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      margin: 16px 0;
      cursor: pointer;

      .label {
        color: var(--font-title);
      }

      .introduce {
        flex: 1;
        color: var(--font-normal);
      }

      .iconfont {
        font-size: 14px;
      }
    }

    .coupon-btn {
      width: 128px;
      height: 24px;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid var(--color);
      color: var(--color);
      text-align: center;
      margin: 0 auto 16px;
      cursor: pointer;
      user-select: none;
    }

  }

  .coupon-list-card-used,
  .coupon-list-card-expires {
    .info-box {
      position: relative;
      height: 124px;
      padding: 16px;
      color: #000;
      background: radial-gradient(circle at left center, transparent 4px, #E3E3E3 0) left top / 4px 12px repeat-y,
      linear-gradient(to left, #E3E3E3, #E3E3E3) 3px / 303px no-repeat,
      radial-gradient(circle at right center, transparent 4px, #E3E3E3 0) right top / 4px 12px repeat-y;

      .tag {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.45);
      }
    }

  }

  .ant-pagination {
    padding: 24px 0;
    background-color: #fff;
    text-align: center;
  }
}

.coupon-center-page {


  .headline {
    text-align: center;
    height: 114px;
    line-height: 114px;
    background-color: #fff;
    .icon-title-1{
      width: 316px;
      height: 40px;
    }
  }

  .coupon-center-main {
    width: 1200px;
    margin: 8px auto;
    padding: 24px;
    background-color: #fff;

    .ant-pagination {
      padding-top: 24px;
      text-align: center;
    }
  }

  .coupon-center-list {
    display: flex;
    flex-wrap: wrap;
  }

  .coupon-center-card {
    width: 374px;
    display: flex;
    margin-bottom: 16px;
    margin-right: 15px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, .2);

    &:nth-child(3n) {
      margin-right: 0;
    }

    .price-box {
      width: 120px;
      height: 108px;
      padding-top: 32px;
      text-align: center;
      color: #fff;
      background: radial-gradient(circle at left center, transparent 3px, #FF3838 0) left top / 4px 12px repeat-y,
      linear-gradient(to left, #FF6969, #FF3838) 3px / 118px no-repeat;

      .money {
        font-size: 24px;
        line-height: 1;

        &:first-letter {
          font-size: 12px;
        }
      }

      .target {
        line-height: 1;
        font-size: 12px;
        margin-top: 8px;
      }
    }

    .info-box {
      display: flex;
      width: 255px;
      height: 108px;
      padding: 16px 0;
      border-radius: 0 2px 2px 0;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(227, 227, 227, 1);
    }

    .intro-box {
      width: 162px;
      padding: 0 12px 0 16px;
      border-right: 1px dashed var(--border-deep);

      .tag {
        display: inline-block;
        padding: 0 8px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 49px;
        background-color: var(--color-hint);
        color: #fff;
      }

      .name {
        font-size: 14px;
        color: var(--font-title);
        font-weight: 600;
      }

      .time {
        font-size: 12px;
        color: var(--font-tips);
      }

      .limit {
        font-size: 11px;
        color: var(--font-normal);
      }
    }

    .status-box {
      width: 92px;
      text-align: center;
      .expired {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }


    .btn-box {
      text-align: center;

      .btn {
        font-size: 12px;
        line-height: 24px;
        padding: 0 8px;
        border-radius: 11px;
        border: 0 none;
        background-color: var(--color-hint);
        color: #fff;
      }
    }
  }
  .coupon-card-use{
    .price-box{
      background: rgba(227, 227, 227, 1);
      color: #000;
      .target{
        opacity: .65;
      }
    }
    .info-box{
      .tag{
        color: var(--font-normal);
        background-color: rgba(227, 227, 227, 1);
      }
    }
  }
}
.sub-account-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%;

  .sub-account-page-main {
    padding: 16px;
  }

  .sub-account-page-main-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .search-box {
      width: 260px;
    }
    .add-sub-account-box {
      .add {
        width: 118px;
        height: 100%;
        text-align: center;
        padding: 6px 0;
        background-color: #02A7F0;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .account-details-page-main-foot {
    text-align: center;
    margin-top: 16px;
  }
  .cont-table {
    td {
      text-align: center;
    }
    .edit, .delete {
      padding: 10px 16px;
      cursor: pointer;
    }
  }
}
.memberLevel-page-wrap-box {
  padding: 16px 24px;
  background: #fff;
  .level-desc {
    margin-top: 10px;
    text-align: right;
    color: #F59A23;
  }
  .memberLevel-page-box {
    .progress-ring-container {
      position: relative;
      margin-top: 18px;
      .progress-ring {
        transform: rotate(90deg); /* 将圆环旋转 -90 度，确保从顶部开始绘制 */
        width: 100px;
        height: 100px;
      }

      .progress-ring-background {
        fill: none;
        stroke: #fff;
        stroke-width: 8;
      }

      .progress-ring-bar1 {
        fill: none;
        stroke: #3169FA;
        stroke-width: 8;
        stroke-linecap: round;
        stroke-dasharray: 339.292; /* 圆的周长 2 * pi * r，54是圆的半径 */
        stroke-dashoffset: 339.292; /* 初始偏移量，表示进度为0 */
        transition: stroke-dashoffset 0.5s ease;
      }
      .progress-ring-bar2 {
        fill: none;
        stroke: #3169FA;
        stroke-width: 8;
        stroke-linecap: round;
        stroke-dasharray: 339.292; /* 圆的周长 2 * pi * r，54是圆的半径 */
        stroke-dashoffset: 339.292; /* 初始偏移量，表示进度为0 */
        transition: stroke-dashoffset 0.5s ease;
      }
      .txt-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }
    .member-card-bg-box {
      position: relative;
      .member-card-year-img-box {
        position: absolute;
        top: 30px;
        left: 254px;
        img {
          width: 44px;
        }
      }
      .member-card-info {
        position: absolute;
        width: 93%;
        left: 50%;
        top: 76%;
        transform: translate(-50%, -76%);
        .member-info {
          .member-id {
            margin-right: 32px;
          }
        }
        .line-box {
          position: relative;
          height: 2px;
          background-color: #fff;
          margin: 10px 0;
          border-radius: 2px;
          .progress-bar {
            position: absolute;
            top: 0;
            width: 30%;
            height: 3px;
            border-radius: 2px;
            background-color: #3169FA;
          }
        }
      }
    }
    .member-header-box-wrap {
      padding: 20px;
      background-image: url("../../assets/images/member/level3-card-02.png");
      background-repeat: repeat-x;
      background-position: bottom left;
      .member-header-box {
        background-color: #f5f5f5;
        padding: 12px 24px;
        .title {
          font-size: 18px;
          margin-bottom: 8px;
        }
        .member-id {
          margin-right: 30px;
        }
        .member-info {

        }
        .line-box {
          position: relative;
          height: 1px;
          background-color: #797979;
          margin: 10px 0;
          .progress-bar {
            position: absolute;
            top: -1px;
            width: 30%;
            height: 3px;
            background-color: #3169FA;
          }
        }
      }
    }
    .top-box {
      display: flex;
      width: 100%;
      padding: 20px;
      background-color: #fff;
      //background-image: url("../../assets/images/bg.jpg");
      .l-item {
        flex: 1;
        .member-info-card {
          margin-bottom: 52px;
          .member-card {
            display: flex;
            position: relative;
            .head-img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 8px;

              img, .svg-icon {
                width: 100%;
                height: 100%;
              }

              .member-level {
                position: absolute;
                width: 25px;
                height: 12px;
                line-height: 12px;
                background-color: #169BD5;
                border-radius: 20px;
                color: #fff;
                left: 23px;
                bottom: -1px;
                text-align: center;
                font-size: 9px;
              }
            }
          }
        }
        .keep-level-box {
          .level-box-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            .level-icon-img {
              height: 22px;
              img {
                height: 100%;
              }
            }
            .level-img {
              height: 24px;
              img {
                height: 100%;
              }
            }
            .level-box {
              position: relative;
              margin-left: 8px;
              .level {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-weight: 700;
                font-size: 18px;
              }
            }
          }
          .tips {
            color: var(--font-tips);
          }
          .keep-level-card {
            background-color: #f5f5f5;
            padding: 20px 28px 28px 28px;
            margin-top: 10px;
            border-radius: 12px;
            .view-rights {
              float: right;
              color: #F59A23;
            }
            .info-box {
              display: flex;
              justify-content: center;
              width: 100%;
              .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: #fff;
                margin-top: 18px;
                .money {
                  font-weight: 700;
                }
              }
            }
          }
          .mt4 {
            margin-top: 4px;
          }
          .mt26 {
            margin-top: 26px;
          }
        }
        .level-up-box {
          .level-desc {
            margin-top: 18px;
            color: #F59A23;
          }
          .level-icon-box {
            i {
              margin-right: 10px;
            }
            .level-icon {
              display: inline-block;
              width: 32px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              border-radius: 5px;
              font-size: 11px;
              background-color: #D7D7D7;
              &.active {
                background-color: #FF8201;
                color: #fff;
              }
            }
          }
          .tips {
            color: var(--font-tips);
          }
          .level-up-card {
            background-color: #f5f5f5;
            padding: 20px 28px 28px 28px;
            margin-top: 10px;
            border-radius: 12px;
            .view-rights {
              float: right;
              color: #F59A23;
            }
            .info-box {
              display: flex;
              justify-content: center;
              width: 100%;
              .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: #fff;
                margin-top: 18px;
                .money {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
      .r-item {
        padding-left: 60px;
        flex: 1;
        .rate-record {
          height: 102px;
          text-align: right;
          color: #F59A23;
        }
        .level-up-box {
          .level-icon-box {
            i {
              margin-right: 10px;
            }
            .level-icon {
              display: inline-block;
              width: 32px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              border-radius: 5px;
              font-size: 11px;
              background-color: #D7D7D7;
              &.active {
                background-color: #FF8201;
                color: #fff;
              }
            }
          }
          .tips {
            color: var(--font-tips);
          }
          .level-up-card {
            background-color: #f5f5f5;
            padding: 20px 28px 28px 28px;
            margin-top: 10px;
            border-radius: 12px;
            .view-rights {
              float: right;
              color: #F59A23;
            }
            .info-box {
              display: flex;
              justify-content: center;
              width: 100%;
              .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: #fff;
                margin-top: 18px;
                .money {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
    .bottom-box {
      padding: 20px 20px 0 20px;
      background-color: #f5f5f5;
      border-radius: 16px;
      .benefit-img {
        img {
          height: 20px;
        }
      }
      .level-list {
        display: flex;
        border-bottom: 1px solid #D7D7D7;
        padding: 16px 0 10px 0;
        white-space: nowrap;
        overflow: hidden;
        .level-txt {
          width: 76px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          position: relative;
          color: #3169FA;
          &.active:before {
            content: "";
            position: absolute;
            bottom: -11px;
            left: 50%;
            display: inline-block;
            width: 48px;
            height: 3px;
            background: #D9001B;
            transform: translateX(-50%);
          }
          .curr-level {
            width: 38px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #D9001B;
            position: absolute;
            padding: 1px 4px;
            left: 19px;
            top: -12px;
            font-size: 8px;
            color: #fff;
          }
        }
      }
      .level-card-box {
        display: flex;
        margin-bottom: 200px;
        .level-card {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          margin-top: 20px;
          width: 160px;
          height: 100px;
          margin-right: 16px;
          padding: 0 16px;
          .circle-box {
            text-align: center;
            line-height: 50px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #D7D7D7;
            margin-right: 6px;
          }
          .txt {
            flex: 1;
          }
          .main-box {
            display: flex;
            margin-right: 100px;
            .level-card-img {
              width: 68px;
              height: 68px;
              border-radius: 50%;
              margin-right: 8px;
              .svg-icon {
                width: 100%;
                height: 100%;
              }
            }
            .info {
              .text-title {
                font-weight: 700;
              }
              .text-tips {
                color: var(--font-tips);
                font-size: 12px;
              }
            }
          }
          .desc-box {
            flex: 1;
          }
        }
      }
    }
  }

  .member-style-1 {
    .member-card-bg-box {
      color: #031c4b;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #196bef;
      }
      .progress-ring-bar2 {
        stroke: #196bef;
      }
      .txt-box {
        color: #196bef;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #196bef;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #196bef;
        }
      }
    }
  }
  .member-style-2 {
    .member-card-bg-box {
      color: #6e4600;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #d69400;
      }
      .progress-ring-bar2 {
        stroke: #d69400;
      }
      .txt-box {
        color: #d69400;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #d69400;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #d69400;
        }
      }
    }
  }
  .member-style-3 {
    .member-card-bg-box {
      color: #713129;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #d06956;
      }
      .progress-ring-bar2 {
        stroke: #d06956;
      }
      .txt-box {
        color: #d06956;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #d06956;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #d06956;
        }
      }
    }
  }
  .member-style-4 {
    .member-card-bg-box {
      color: #763700;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #ca5c00;
      }
      .progress-ring-bar2 {
        stroke: #ca5c00;
      }
      .txt-box {
        color: #ca5c00;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #ca5c00;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #ca5c00;
        }
      }
    }
  }
  .member-style-5 {
    .member-card-bg-box {
      color: #530f43;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #ac1887;
      }
      .progress-ring-bar2 {
        stroke: #ac1887;
      }
      .txt-box {
        color: #ac1887;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #ac1887;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #ac1887;
        }
      }
    }
  }
  .member-style-6 {
    .member-card-bg-box {
      color: #4e024f;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #880e81;
      }
      .progress-ring-bar2 {
        stroke: #880e81;
      }
      .txt-box {
        color: #880e81;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #880e81;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #880e81;
        }
      }
    }
  }
  .member-style-7 {
    .member-card-bg-box {
      color: #3f275e;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #6f3aa1;
      }
      .progress-ring-bar2 {
        stroke: #6f3aa1;
      }
      .txt-box {
        color: #6f3aa1;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #6f3aa1;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #6f3aa1;
        }
      }
    }
  }
  .member-style-8 {
    .member-card-bg-box {
      color: #d29ff5;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #d19df0;
      }
      .progress-ring-bar2 {
        stroke: #d19df0;
      }
      .txt-box {
        color: #d19df0;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #d19df0;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #d19df0;
        }
      }
    }
  }
  .member-style-9 {
    .member-card-bg-box {
      color: #beab9b;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #986b48;
      }
      .progress-ring-bar2 {
        stroke: #986b48;
      }
      .txt-box {
        color: #986b48;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #986b48;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #986b48;
        }
      }
    }
  }
  .member-style-10 {
    .member-card-bg-box {
      color: #9ca5ce;
    }
    .progress-ring-container {
      .progress-ring-bar1 {
        stroke: #1968e4;
      }
      .progress-ring-bar2 {
        stroke: #1968e4;
      }
      .txt-box {
        color: #1968e4;
      }
    }
    .member-card-bg-box {
      .member-card-info {
        .line-box {
          .progress-bar {
            background-color: #1968e4;
          }
        }
      }
    }
    .bottom-box {
      .level-list {
        .level-txt {
          color: #1968e4;
        }
      }
    }
  }
}

.integral-rule {
  margin-top: 10px;
  background: #fff;
  li {
    padding: 0 12px 20px 12px;
    .title {
      font-size: 15px;
      color: var(--font-title);
      line-height: 32px;
      border-bottom: 1px solid var(--border-deep);
    }
    .main {
      padding-top: 11px;
      p {
        font-size: 14px;
        color: var(--font-tips);
        margin-bottom: 8px;
      }
    }
  }
}

.member-rule {
  margin-top: -12px;
  .member-rule-title {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
  li {
    .main {
      padding-top: 0;
      p {
        color: var(--font-normal);
      }
      .member-level-box {
        .t-head {
          display: flex;
          background-color: #F2F2F2;
          line-height: 30px;
          .tit {
            width: 20%;
          }
          .content {
            flex: 1;
          }
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .t-row {
          display: flex;
          line-height: 30px;
          .tit {
            width: 20%;
          }
          .content {
            flex: 1;
          }
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.active {
            background-color: #F2F2F2;
          }
        }
      }
    }
    .title {
      position: relative;
      border-bottom: none;
      line-height: 28px;
      .circle {
        position: absolute;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #CB1E36;
        left: -17px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .fw700 {
    font-weight: 700;
  }
}
.member-rule-modal {
  .ant-modal-body {
    height: 715px;
    overflow-y: auto;
  }
  .mt-10 {
    margin-top: 10px;
  }
}
.benefit-card-box {
  display: flex;
  align-items: center;
  padding: 20px 0;
  .benefit-card {
    position: relative;
    width: 140px;
    margin-right: 12px;
    flex-shrink: 0;
    text-rendering: optimizeLegibility;
    img {
      width: 100%;
    }
    .score-multiple {
      position: absolute;
      width: 100%;
      right: 9px;
      bottom: 18px;
      font-size: 9px;
      color: #D73B11;
      font-weight: 700;
      text-align: right;
    }
    .target-txt1, .target-txt2 {
      position: absolute;
      width: 100%;
      left: 84px;
      bottom: 30px;
      font-size: 9px;
      color: #D73B11;
      font-weight: 700;
      text-align: left;
    }
    .target-txt2 {
      bottom: 18px;
    }
    .money, .multiple {
      position: absolute;
      width: 100%;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 27px;
      color: #D73B11;
      font-weight: 700;
      i {
        font-size: 24px;
        font-weight: 700;
      }
    }
    .multiple {
      .multiple-txt {
        font-size: 24px;
        font-weight: 700;
      }
    }
    .multiple-1 {
      left: 23px;
    }
    .multiple-2 {
      left: 11px;
    }
    .multiple-3 {
      left: 5px;
    }
    .count {
      position: absolute;
      right: 5px;
      top: 0;
      font-size: 8px;
      color: #D73B11;
      font-weight: 700;
    }
  }
}
.tips-tit {
  font-size: 14px;
  padding-bottom: 16px;
}
.user-member-card-bg-box {
  position: relative;
  .member-card-year-img-box {
    position: absolute;
    top: 30px;
    left: 208px;
    img {
      width: 44px;
    }
  }
  .level-center-img-box {
    position: absolute;
    left: 27.5%;
    top: 10%;
    height: 14px;
    transform: translate(-27.5%, -10%);
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user-member-card-info {
    position: absolute;
    width: 93%;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -38%);
    .member-id {
      margin-right: 32px;
    }
    .line-box {
      position: relative;
      height: 2px;
      background-color: #fff;
      margin: 10px 0;
      border-radius: 2px;
      .progress-bar {
        position: absolute;
        top: 0;
        width: 30%;
        height: 3px;
        border-radius: 2px;
        background-color: #3169FA;
      }
    }
  }
  .curr-user-level {
    position: absolute;
    width: 93%;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    font-size: 16px;
    font-weight: 700;
  }
  .user-benefit-card-box {
    position: absolute;
    width: 93%;
    left: 50%;
    bottom: 6%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    .user-benefit-card {
      position: relative;
      width: 98px;
      margin-right: 12px;
      flex-shrink: 0;
      text-rendering: optimizeLegibility;
      img {
        width: 100%;
      }
      .score-multiple {
        position: absolute;
        width: 100%;
        left: 67px;
        bottom: 8px;
        font-size: 7px;
        color: #D73B11;
        font-weight: 700;
        text-align: left;
      }
      .target-txt1, .target-txt2 {
        position: absolute;
        width: 100%;
        left: 56px;
        bottom: 18px;
        font-size: 7px;
        color: #D73B11;
        font-weight: 700;
        text-align: left;
      }
      .target-txt2 {
        bottom: 8px;
      }
      .money, .multiple {
        position: absolute;
        width: 100%;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        color: #D73B11;
        font-weight: 700;
        i {
          font-size: 10px;
          font-weight: 700;
        }
      }
      .multiple {
        left: 15px;
        .multiple-txt {
          font-size: 15px;
          font-weight: 700;
        }
      }
      .multiple-1 {
        left: 18px;
      }
      .multiple-2 {
        left: 8px;
      }
      .multiple-3 {
        left: 3px;
      }
      .count {
        position: absolute;
        right: 3px;
        top: -1px;
        font-size: 7px;
        color: #D73B11;
        font-weight: 700;
      }
    }
  }
}
.user-member-style-1 {
  .user-member-card-info {
    color: #031c4b;
    .line-box {
      .progress-bar {
        background-color: #196bef;
      }
    }
  }
  .curr-user-level {
    color: #ff9100;
  }
}
.user-member-style-2 {
  .user-member-card-info {
    color: #724c0a;
    .line-box {
      .progress-bar {
        background-color: #d69400;
      }
    }
  }
  .curr-user-level {
    color: #ff9100;
  }
}
.user-member-style-3 {
  .user-member-card-info {
    color: #713129;
    .line-box {
      .progress-bar {
        background-color: #d06853;
      }
    }
  }
  .curr-user-level {
    color: #ff9100;
  }
}
.user-member-style-4 {
  .user-member-card-info {
    color: #7a3d0a;
    .line-box {
      .progress-bar {
        background-color: #bc5700;
      }
    }
  }
  .curr-user-level {
    color: #a20000;
  }
}
.user-member-style-5 {
  .user-member-card-info {
    color: #530f43;
    .line-box {
      .progress-bar {
        background-color: #b11b8b;
      }
    }
  }
  .curr-user-level {
    color: #390032;
  }
}
.user-member-style-6 {
  .user-member-card-info {
    color: #350535;
    .line-box {
      .progress-bar {
        background-color: #880e84;
      }
    }
  }
  .curr-user-level {
    color: #fff;
  }
}
.user-member-style-7 {
  .user-member-card-info {
    color: #3f275e;
    .line-box {
      .progress-bar {
        background-color: #703ba5;
      }
    }
  }
  .curr-user-level {
    color: #ff9500;
  }
}
.user-member-style-8 {
  .user-member-card-info {
    color: #d29ff5;
    .line-box {
      .progress-bar {
        background-color: #d19df0;
      }
    }
  }
  .curr-user-level {
    color: #fabe00;
  }
}
.user-member-style-9 {
  .user-member-card-info {
    color: #beab9b;
    .line-box {
      .progress-bar {
        background-color: #986b48;
      }
    }
  }
  .curr-user-level {
    color: #fabe00;
  }
}
.user-member-style-10 {
  .user-member-card-info {
    color: #9ca5ce;
    .line-box {
      .progress-bar {
        background-color: #1968e4;
      }
    }
  }
  .curr-user-level {
    color: #fabe00;
  }
}
.grid-benefit-card-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 12px;
  column-gap: 12px;
  background-color: #fff;
  padding: 12px;
  .benefit-card {
    position: relative;
    img {
      width: 100%;
    }
    .target-money {
      position: absolute;
      width: 100%;
      left: 50%;
      bottom: 18px;
      transform: translateX(-50%);
      font-size: 11px;
      color: #D73B11;
      font-weight: 700;
      text-align: center;
    }
    .money {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -30%);
      font-size: 36px;
      color: #D73B11;
      font-weight: 700;
      text-align: center;
      i {
        font-size: 18px;
        font-weight: 700;
      }
      .multiple-txt {
        font-size: 22px;
        font-weight: 700;
      }
    }
    .count {
      position: absolute;
      right: 6px;
      top: 0;
      font-size: 11px;
      color: #D73B11;
      font-weight: 700;
    }
    .count-1 {
      right: 8px;
    }
  }
}
.coupon-level-box {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 588px;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 4px;
  .close-coupon-img-box {
    position: absolute;
    right: 36px;
    top: 18px;
    width: 22px;
    height: 22px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .benefit-level {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    font-size: 26px;
    color: #7A3621;
    font-weight: 700;
  }
  .img-box {
    position: relative;
    .grid-benefit-card-box {
      position: absolute;
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
      width: 86%;
      background-color: transparent;
    }
    .grid-benefit-card-box-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 75px;
      .benefit-card {
        img {
          width: 147px;
        }
        .money {
          font-size: 56px;
          top: 23%;
          .multiple-txt {
            font-size: 34px;
            font-weight: 700;
          }
        }
        .target-money {
          bottom: 12px;
          font-size: 14px;
        }
      }
    }
    .grid-benefit-card-box-2 {
      grid-template-columns: repeat(2, 1fr);
      width: 72%;
      top: 76px;
      .benefit-card {
        .count {
          top: 1px;
        }
        .count-1 {
          right: 14px;
        }
        img {
          width: 147px;
        }
        .money {
          font-size: 40px;
          top: 23%;
          .multiple-txt {
            font-size: 28px;
            font-weight: 700;
          }
        }
        .target-money {
          bottom: 10px;
          font-size: 14px;
        }
      }
    }
    .grid-benefit-card-box-3 {
      grid-template-columns: repeat(2, 1fr);
      width: 72%;
      .benefit-card {
        .count {
          top: 1px;
        }
        .count-1 {
          right: 12px;
        }
        .target-money {
          bottom: 6px;
          font-size: 12px;
        }
        .money {
          font-size: 37px;
          top: 23%;
          .multiple-txt {
            font-size: 28px;
            font-weight: 700;
          }
        }
      }
    }
    .grid-benefit-card-box-4 {
      .benefit-card {
        .money {
          font-size: 34px;
        }
        .target-money {
          bottom: 6px;
        }
      }
    }
    .grid-benefit-card-box-5 {
      row-gap: 9px;
      column-gap: 10px;
      top: 31px;
      .benefit-card {
        .count {
          top: 1px;
          font-size: 10px;
        }
        .count-1 {
          right: 8px;
        }
        .target-money {
          bottom: 4px;
          font-size: 11px;
        }
        .money {
          font-size: 32px;
          top: 23%;
          i {
            font-size: 16px;
          }
          .multiple-txt {
            font-size: 28px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
.single-level-box-wrap {
  display: flex;
  align-items: center;
  padding: 20px 0 0 20px;
  margin-bottom: -16px;
  .level-icon-img {
    height: 22px;
    img {
      height: 100%;
    }
  }
  .level-img {
    height: 24px;
    img {
      height: 100%;
    }
  }
  .level-box {
    position: relative;
    margin-left: 8px;
    .level {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: 700;
      font-size: 18px;
    }
  }
}


